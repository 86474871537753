import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GridItem from "../components/gridItem"

const ThemesPage = ({ data }) => {
    const themes = data.wpgraphql.qlThemes.edges
    let themesToRender = []

    // Remove themes with categories "hidden" or "beta"
    themes.forEach((theme, indexTheme) => {
        let flagVar = true
        theme.node.qlThemeCategories.edges.forEach(category => {
            if (
                "hidden" === category.node.slug ||
                "beta" === category.node.slug
            ) {
                flagVar = false
            }
        })
        if (flagVar) {
            themesToRender.push(theme)
        }
    })

    return (
        <Layout>
            <SEO
                title="Themes"
                description="All WordPress themes from Quema Labs"
            />

            <div className="themes-grid">
                {themesToRender.map((theme, index) => (
                    <GridItem item={theme.node} key={index} index={index} />
                ))}
            </div>
        </Layout>
    )
}

export default ThemesPage

// Query passed as data to ThemesPage
export const pageQuery = graphql`
    query {
        wpgraphql {
            qlThemes(first: 100) {
                edges {
                    node {
                        title
                        slug
                        subtitle
                        featuredImage {
                            sourceUrl
                            mediaItemId
                            modified
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 500, quality: 75) {
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        sizes
                                    }
                                }
                            }
                        }
                        qlThemeCategories {
                            edges {
                                node {
                                    slug
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
